body {
  font-family: 'Poppins', sans-serif;
}
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.whatsapp-btn {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: rgb(0, 147, 49);
  ;
  color: white;
  text-decoration: none;
  border-radius: 5px;  
}

.whatsapp-btn:hover {
  background-color: #2bff00;
  
}


form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="submit"] {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}
